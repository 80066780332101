.image-viewer-container {
    height: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-bottom: 16px;
    padding-top: 16px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.viewer {
    height: calc(100vh - 94px);
    width: calc(100% - 36px);
    transition: width 0.3s ease-in-out;
}

.viewer.sidebar-open {
    width: calc(100% - 336px);
}
  
.sidebar {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: calc(100% - 64px);
    position: absolute;
    top: 32px;
    right: -300px;
    line-height: 14px;
    background-color: #ffffff;
    transition: right 0.3s ease-in-out;
    overflow-y: hidden;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.sidebar.open {
    right: 0;
}

.sidebar-hover-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 100%;
    background-color: #78909c;
    cursor: pointer;
    z-index: 3;
    transition: background-color 0.15s ease;
}

.sidebar-hover-area:hover {
    background-color: #557b92;
}

.toggle-info-box {
    width: 20px;
    height: 20px;
    background-color: transparent;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 4;
    pointer-events: none;
}

.toggle-info-box svg {
    font-size: 20px;
    color: #ffffff;
}

.info-box-content {
    width: 300px;
    height: 100%;
    display: block;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
}

.info-box-inner {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.prediction-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 26px;
}

.prediction-toggle input {
    margin-right: 10px;
    transform: scale(1.2);
    cursor: pointer;
}

.prediction-toggle span {
    font-size: 14px;
    font-weight: 700;
    color: #040404;
}

.image-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
}

.image-info {
    padding: 10px 20px 10px 20px;
    border: 1px solid #dddddd;
    border-radius: 6px;
    width: calc(100% - 20px);
    color: #000;
    box-sizing: border-box;
    font-size: 12px;
    margin-bottom: 26px;
}

.image-info p {
    margin: 6px 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

.analysis-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide-analysis-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 0px;
    color: #494c4d;
}

.stain-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 20px);
    color: #000;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid silver;
    border-radius: 4px;
    padding: 16px;
    margin-top: 16px;
}

.total-cells {
    margin: 0px 0px 10px 0px;
    color: #494c4d;
}

.stain-info {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stain-info:last-child {
    margin-bottom: 0;
}

.stain-info h4 {
    margin-top: 3px;
    margin-bottom: 5px;
}

.stain-info p {
    margin: 3px 0;
}

.download-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 10px;
    margin-top: 26px;
}

.download-button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #78909c;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: calc(100% - 30px);
    max-width: 200px;
    transition: background-color 0.15s ease;
}

.download-button:hover {
    background-color: #557b92;
}

.download-button:disabled {
    background-color: #dddddd;
    cursor: not-allowed;
}

.no-image-message {
    font-size: 16px;
    color: #494c4d;
    text-align: center;
    padding: 20px;
    margin: auto 0;
}

#openseadragon-viewer {
    border: 2px solid #dddddd;
}

.clear-button {
    position: absolute;
    top: 26px;
    left: 12px;
    width: 28px;
    height: 28px;
    background-color: #78909c;
    border: none;
    border-radius: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.15s ease;
    padding: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.clear-button:hover {
    background-color: #557b92;
}

.clear-button svg {
    font-size: 20px;
}